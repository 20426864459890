@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arima:wght@100;300;400;500;700&family=Eczar:wght@400;500;700&family=Hind+Madurai:wght@300;400;500;700&family=Hind+Siliguri:wght@300;400;500;700&family=Laila:wght@300;400;500;700&family=Noto+Sans+Malayalam:wght@100;300;400;500;700&family=Noto+Sans+Tamil:wght@100;300;400;500;700&family=Noto+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400&family=Playfair+Display:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,400&family=Rozha+One&family=Tiro+Bangla:ital@0;1&family=Tiro+Telugu:ital@0;1&display=swap');


.outercontainer {
  position: absolute;
  width: 100%;
  max-width: 358px;
  /* width: 30%; */
  height: 100%;
  padding: 20px 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.editable {
  max-height: max-content;
  /* border: 1px solid white; */
  display: flex;
  /* overflow: auto; */
  justify-content: center;
  flex-direction: column;
}

.emp {
  text-align: right;
  color: darkred;
}

.head {
  margin: 0 auto;
  width: 96%;
  height: fit-content;
  /* border: 1px solid red; */
  display: flex;
  padding: 5px 0;
  padding-right: 28px;
  padding-bottom: 15px;
  /* border-bottom: 1px solid rgba(228,228,228,.30); */
}

.arrow {
  width: 10%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topic {
  width: 90%;
  /* color: white; */
  text-align: center;
  font-size: 17px;
  font-weight: 700;
}

.buttoncontainer {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  border: none;
}

.content {
  width: 90%;
  height: 90%;
  /* border: 1px solid red; */
  margin: 0 auto;
  color: white;
}

.inputbtns {
  padding: 5px 15px;
  margin: 5px 0;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 0 2px;
}

.headtag {
  /* color: white; */
  font-size: 14px;
}

.inputtag {
  width: 90%;
  height: 100%;
  border: none;
  background-color: transparent;
  font-size: 14px;
  outline: none;
}

.namecont {
  padding-top: 20px;
}

.cont {
  padding-top: 12px;

}

.dinputtag {

  width: 100%;
  height: '44px';
  border: none;
  background-color: transparent;
  font-size: 14px;
  outline: none;
  text-transform: uppercase;
}

.vertical-line {

  height: 80%;
  margin: 0 10px;
}

.mobilecont {
  justify-content: start;
}

.drop1 {
  background-color: transparent;
}

.all {

  padding-top: 10px;
  /* border: 1px solid white; */
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.gender {
  padding: 0 10px;
  box-shadow: 0 0 2px;
  width: 30%;
  /* border: 1px solid ; */
  height: 44px;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
}

.active {
  box-shadow: none;
}

.blur {
  opacity: 0.3;
}

.noSaved{
  height: fit-content;
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: .6;
  font-size: 14px;
}

.common-select-box {

  :global(.ant-select-focused):not(.ant-select-disabled),
  :global(.ant-select-single):not(.ant-select-customize-input),
  :global(.ant-select-selector),
  :global(.ant-picker-focused) {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    border-radius: 2px !important;
  }

  :global(.ant-select-single):not(.ant-select-customize-input),
  :global(.ant-select-selector) {
    border: none !important;
    background-color: transparent !important;
    text-align: left !important;
    height: 35px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    border-radius: 4px !important;
  }

  :global(.ant-select-selection-placeholder) {
    color: #cfcfcf;
  }

  :global(.ant-select-selection-item) {
    color: inherit;
  }
}

.common-dropdown-select {
  :global(.ant-select-item-option-active):not(.ant-select-item-option-disabled) {
    background-color: #fff;
  }

  :global(.ant-select-item-option-selected):not(.ant-select-item-option-disabled) {
    font-weight: 600;
    background-color: transparent;
  }

  :global(.ant-select-item-option) {
    &:hover {
      color: #fff;
      background-color: #fff;
    }
  }
}

.dropdown-options {
  padding: 5px 9px;

  :global(.ant-select-item-option-selected):not(.ant-select-item-option-disabled),
  :global(.ant-select-item-option-state) {
    color: #3d8dfa !important;
  }
}

.dropdown-options:hover {
  background-color: #3d8dfa !important;
  color: #fff;
  border-radius: 4px !important;

  :global(.ant-select-item-option-selected):not(.ant-select-item-option-disabled),
  :global(.ant-select-item-option-state) {
    color: #fff !important;
  }
}

.cards-details {
  padding-top: 20px;
}

.card-container {
  user-select: none;
}

.card-component {
  position: relative;
  margin-top: 5px;
  /* experimental may need to remove*/
  width: 100%;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.tickmark {
  /* width: 22px; */
  display: flex;
  position: absolute;
  align-items: center;
  right: 5px;
  justify-content: center;
}

.addCard {
  gap: 5px;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.addCardInput {
  width: 90%;
  height: 90%;
  margin: 0 auto;
  color: white;
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  margin-top: 20px;
}

.inputbtns input:focus {
  outline: none;
  box-shadow: none;
}

.card-img {
  width: 30px;
  height: 20px;
}
.popup_box{
  z-index: 2;
  position: absolute;
  background-color: #ffffff;
  width: max-content;
  height: max-content;
  right: 15px;
  top:5px;
  padding: 5px 10px;
  box-shadow: 0 4px 10px rgba(18, 18, 18, .2);
  border-radius: 6px;
  color: #000000;
  font-weight: 500;
  cursor: pointer;
}
/* media query for mobile */
@media (max-width: 767px) {

  .outercontainer {
    max-width: 100%;
    max-width: 100%;
    overflow: auto;
  }
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.loader {
  border: 16px solid #d3d9dd;
  border-top: 16px solid #b4b4b4;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1.5s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}